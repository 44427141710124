import { Icon } from '@chakra-ui/react'
import React from 'react'
import colors from '../../theme/colors'

function UxTrackIcon({ active = false, ...props }) {
    return (
        <Icon
            viewBox={'0 0 80 80'}
            width={'20'}
            height={'20'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle r={40} transform="matrix(1 0 0 -1 40.536 40)" fill={active ? colors.red.accent : colors.black} />
            <path
                d="M42.449 25.018l-1.915 1.92-1.921-1.922c-4.898-4.898-12.84-4.898-17.737 0a12.54 12.54 0 00-3.093 12.65h8.168l3.31-6.997a4.083 4.083 0 017.314-.137l4.62 8.883 3.68-4.813a4.083 4.083 0 016.317-.209l2.864 3.273h9.239c1.364-4.32.338-9.222-3.088-12.648-4.907-4.907-12.852-4.907-17.758 0zm-3.15 36.157L23.956 45.832h4.58c1.578 0 3.015-.91 3.69-2.337l.899-1.898 3.788 7.286a4.083 4.083 0 006.867.596l4.552-5.953.798.912a4.083 4.083 0 003.073 1.394h4.923L41.774 61.175a1.75 1.75 0 01-2.475 0zm-4.782-29.542a1.75 1.75 0 00-3.147.034l-3.942 8.332h-8.476a1.75 1.75 0 000 3.5h9.583a1.75 1.75 0 001.582-1.002l2.878-6.082 5.975 11.95a1.75 1.75 0 002.984.242l6.296-8.717 2.635 3.011c.332.38.813.598 1.317.598h9.917a1.75 1.75 0 100-3.5h-9.123l-3.56-4.07a1.75 1.75 0 00-2.736.129l-5.892 8.157-6.29-12.582z"
                fill={active ? colors.white : colors.red.accent}
            />
        </Icon>
    )
}

export default UxTrackIcon
