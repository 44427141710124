import { Icon } from '@chakra-ui/react'
import React from 'react'
import colors from '../../theme/colors'

function UxStrategyIcon({ active = false, ...props }) {
    return (
        <Icon
            viewBox={'0 0 80 80'}
            width={'20'}
            height={'20'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle r={40} transform="matrix(1 0 0 -1 40.536 40)" fill={active ? colors.green.accent : colors.black} />
            <path
                d="M48.784 56.332l-.678 2.936a5.25 5.25 0 01-4.711 4.05l-.404.015h-4.928a5.25 5.25 0 01-5.009-3.678l-.106-.39-.677-2.933h16.513zM40.528 16.67c9.343 0 16.917 7.574 16.917 16.916 0 4.986-2.183 9.552-6.453 13.629a.582.582 0 00-.165.29l-1.231 5.327H31.46l-1.227-5.326a.583.583 0 00-.166-.291c-4.272-4.078-6.456-8.643-6.456-13.629 0-9.342 7.574-16.916 16.916-16.916z"
                fill={active ? colors.white : colors.green.accent}
            />
        </Icon>
    )
}

export default UxStrategyIcon
