import {IIFLLogin} from '@yobeehq/ui-components';
import {useCallback, useEffect, useState} from 'react';
import useLeadGenCreate from '../../apiHandlers/leadGen/create';
import {parseCookies} from 'nookies';

const API_URL = process.env.NEXT_PUBLIC_LEAD_GEN_BASE_API;
const API_KEY = process.env.NEXT_PUBLIC_LEAD_GEN_API_KEY;

if (API_URL === undefined) {
	throw new Error('Missing API_URL in environment');
}
if (API_KEY === undefined) {
	throw new Error('Missing API_KEY in environment');
}
//
const IIFLWidgetWrapper = ({setDisableCheckbox}) => {
	const urlParams = parseCookies(null)['urlParams']
		? JSON.parse(parseCookies(null)['urlParams'])
		: {};

	const updateLead = useCallback(
		({mobile = '', email = '', otp = false}) => {
			if (mobile !== '' || mobile.length === 10) {
				setDisableCheckbox(true);
				const raw = JSON.stringify({
					broker_id: 'iifl',
					mobile: parseInt(mobile, 10),
					...(email !== '' && {email}),
					otp_verified: otp,
					...(Object.keys(urlParams).length > 0
						? {
								url_parameters: [urlParams],
						  }
						: {url_parameters: []}),
				});
				const headers = new Headers();
				headers.append('Content-Type', 'application/json');
				headers.append('x-api-key', `${API_KEY}`);

				fetch(`${API_URL}/create`, {
					method: 'POST',
					headers,
					body: raw,
					redirect: 'follow',
				})
					.then((response) => {
						if (!response.ok) {
							console.error({
								statusText: response.statusText,
								status: response.status,
							});
						}
						return response.json();
					})
					.then((data) => {})
					.catch((error) => {
						console.error(error);
					});
			}
		},
		[setDisableCheckbox]
	);
	return (
		<IIFLLogin
			onChange={updateLead}
			buttonBgColor={'blue.500'}
			inputSpacing={16}
		/>
	);
};

export default IIFLWidgetWrapper;
