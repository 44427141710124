import {Box, Divider, Grid, GridItem, Heading, Stack, Text} from '@chakra-ui/react'
import {IntersectionObserver} from '../../common/IntersectionObserver'
import UxOrderIcon from '../../icons/ux-order'
import UxStrategyIcon from '../../icons/ux-strategy'
import UxTrackIcon from '../../icons/ux-track'
import _UXStepProgressBar from './progress'
import {motion} from 'framer-motion'
import {useState} from 'react'

const StepContent = ({title, text, icon, isActive, handleOnClick, nextStep}) => {
    const UxIcon = icon
    return (
        <>
            <Stack spacing={6} onClick={handleOnClick} cursor={'pointer'}>
                <Box position="relative">
                    <Box position="absolute" top={'50%'} left={{lg: '-104px', md: '-60px'}} mt={'-20px'}>
                        <_UXStepProgressBar onComplete={nextStep} active={isActive}/>
                    </Box>
                    <UxIcon height={'60px'} active={isActive}/>
                </Box>
                <Stack>
                    <Heading color={isActive ? 'white' : 'gray.500'} fontSize={'1.75rem'} lineHeight={1.2}>
                        {title}
                    </Heading>
                    <Text
                        color={isActive ? 'white' : 'gray.500'}
                        fontWeight={'medium'}
                        lineHeight={1.5}
                        letterSpacing={1}
                        // textAlign={'left'}
                    >
                        {text}
                    </Text>
                </Stack>
            </Stack>
        </>
    )
}

const SlideIn = ({children}) => {
    const variants = {
        from: {
            opacity: 0,
            x: '-100px',
        },
        to: {
            opacity: 1,
            x: 0,
        },
    }
    return (
        <motion.div variants={variants} initial={'from'} animate={'to'}>
            {children}
        </motion.div>
    )
}

const _UXStepNonMobile = () => {
    const [step, setStep] = useState(0)
    const handleNextStep = () => {
        setStep((prev) => {
            if (prev === 2) {
                return 0
            }

            return prev + 1
        })
    }

    const changeStepTo = (toStep) => () => {
        setStep(toStep)
    }
    return (
        <IntersectionObserver reset={true}>
            <Box>
                <Grid
                    templateColumns={{lg: 'auto 1fr', md: 'auto 1fr'}}
                    gridGap={{lg: '83px', md: '10'}}
                >
                    <GridItem>
                        <Divider
                            orientation="vertical"
                            border="1px solid"
                            __css={{
                                borderImageSlice: 1,
                                borderImageSource: `linear-gradient(
                                 180deg, rgba(60, 62, 67, 0) 0%,
                                #3C3E43 7.66%, #3C3E43 52.6%,
                                 #3C3E43 78.12%,
                                  rgba(60, 62, 67, 0) 100%)`,
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <Stack spacing={'12'} py={'7'}>
                            <StepContent
                                isActive={step === 0}
                                icon={UxStrategyIcon}
                                title={'Pick a basket'}
                                text={'Yobee is for every trader - covering different segments, styles, and risks.'}
                                handleOnClick={changeStepTo(0)}
                                nextStep={handleNextStep}
                            />
                            <StepContent
                                isActive={step === 1}
                                icon={UxOrderIcon}
                                title={'Place your order'}
                                text={'Trades are executed in your broker account for the amount set.'}
                                handleOnClick={changeStepTo(1)}
                                nextStep={handleNextStep}
                            />
                            <StepContent
                                isActive={step === 2}
                                icon={UxTrackIcon}
                                title={'Track & exit anytime'}
                                text={'Live order status and real time profits - you’re in control.'}
                                handleOnClick={changeStepTo(2)}
                                nextStep={handleNextStep}
                            />
                        </Stack>
                    </GridItem>
                </Grid>
            </Box>
        </IntersectionObserver>
    )
}

export default _UXStepNonMobile
