import {
	Box,
	Button,
	chakra,
	Checkbox,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Grid,
	GridItem,
	HStack,
	Image,
	LightMode,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import Container from '../../common/Container';
import EarlyAccessSignup from '../../early-access/signup';
import {
	AiFillFacebook,
	AiFillInstagram,
	AiFillLinkedin,
	AiFillTwitterSquare,
} from 'react-icons/ai';
import useBreakpointName from '../../../hooks/breakpointName';
import HomepageUXSteps from '../uxSteps';
import IIFLWidgetWrapper from '../../early-access/iifl-widget-wrapper';
import {useState} from 'react';

const IconWrapper = chakra(Box, {
	baseStyle: {
		color: 'gray.500',
		fontSize: '24px',
		_hover: {color: 'gray.100'},
		cursor: 'pointer',
	},
});

const SocialIcons = () => (
	<Box pt={[10, null, null, 5]} pb={[10, 10, 0, 0]}>
		<HStack justifyContent={['center', null, 'start']} spacing={'20px'}>
			<IconWrapper
				as={'a'}
				href={'https://www.instagram.com/tradewithyobee/'}
				target={'_blank'}
			>
				<AiFillInstagram />
			</IconWrapper>
			<IconWrapper
				as={'a'}
				href={'https://twitter.com/yobeehq'}
				target={'_blank'}
			>
				<AiFillTwitterSquare />
			</IconWrapper>
			<IconWrapper
				as={'a'}
				href={'https://www.linkedin.com/company/yobeehq/'}
				target={'_blank'}
			>
				<AiFillLinkedin />
			</IconWrapper>
			<IconWrapper
				as={'a'}
				href={'https://www.facebook.com/YobeeHQ'}
				target={'_blank'}
			>
				<AiFillFacebook />
			</IconWrapper>
		</HStack>
	</Box>
);

const _AccountOpenKnowMore = ({device, onClose}) => {
	const handleClose = () => {
		onClose?.();
	};
	return (
		<Stack spacing={5} px={4} py={6}>
			<HStack spacing={4}>
				<Image src={'/illustrations/build-house.svg'} height={'70px'} />
				<Stack spacing={1}>
					<Text fontWeight={'semibold'} fontSize={'18px'} lineHeight={1.5}>
						Begin your trading journey with Yobee
					</Text>
					<HStack spacing={1} align={'center'}>
						<Text fontSize={'xs'} fontWeight={'semibold'} color={'gray.100'}>
							In partnership with
						</Text>
						<Image src={'/broker-logo/full/iifl.svg'} height={'20px'} />
					</HStack>
				</Stack>
			</HStack>
			<Stack spacing={'10px'} fontSize={'sm'} lineHeight={1.5}>
				<Text>Superior mobile app designed for traders</Text>
				<Text>FREE investments in ETFs, MFs, IPOs</Text>
				<Text>
					Flat Rs. 20 for all trades in equity, derivatives, commodity, currency
				</Text>
				<Text>Industry leading in-house research & advisory</Text>
			</Stack>
			{device === 'mobile' && (
				<LightMode>
					<Button
						w={'full'}
						size={'md'}
						colorScheme={'blue'}
						onClick={handleClose}
					>
						Got it!
					</Button>
				</LightMode>
			)}
		</Stack>
	);
};

const HomepageHero = () => {
	const device = useBreakpointName();
	const [showIIFLWidget, setShowIIFLWidget] = useState(true);
	const [disableCheckbox, setDisableCheckbox] = useState(false);
	const {
		isOpen: isKnowMoreOpen,
		onOpen: onKnowMoreOpen,
		onClose: onKnowMoreClose,
	} = useDisclosure();

	const handleKnowMoreClick = () => {
		if (device === 'mobile') {
			onKnowMoreOpen();
		}
	};

	return (
		<>
			<Container pt={[10, null, 0]}>
				<Grid
					gap={['40px', null, '95px']}
					templateColumns={['auto', null, '1fr 1fr', '1fr 1fr']}
					templateRows={['auto auto', null, 'auto', 'auto']}
					textAlign={['center', 'null', 'left']}
				>
					<GridItem alignSelf={'center'}>
						<Text
							as={'h1'}
							fontSize={['2.375rem', null, '3.75rem']}
							fontWeight={'bold'}
							textAlign={['center', null, 'left']}
							lineHeight={1.2}
							color={'white'}
							pb={6}
						>
							Easier than buying mutual funds
						</Text>
						<Text
							as={'p'}
							w={['100%', '90%']}
							mx={['auto', null, 0]}
							fontSize={['1rem', null, '1.25rem']}
							textAlign={['center', null, 'left']}
							lineHeight={1.5}
							pb={6}
							color={'gray.300'}
						>
							Yobee will offer short term trading baskets of multiple signals or
							recommendations
						</Text>
						<Text
							color={'gray.200'}
							pb={6}
							fontSize={['1rem', null, '1.25rem']}
						>
							Launching soon with your favourite brokers
						</Text>
						<Text color={'gray.300'} fontSize={'md'} fontWeight={'bold'} pb={6}>
							Join the Yobee waitlist
						</Text>

						<Box w={['full', null, null, '450px']}>
							{!showIIFLWidget && <EarlyAccessSignup />}
							{showIIFLWidget && (
								<IIFLWidgetWrapper setDisableCheckbox={setDisableCheckbox} />
							)}
						</Box>

						<LightMode>
							{!disableCheckbox && (
								<HStack
									py={6}
									spacing={[2, null, 4]}
									justifyContent={['space-between', null, 'start']}
								>
									<Checkbox
										isDisabled={disableCheckbox}
										defaultChecked={true}
										color={'gray.200'}
										colorScheme={'blue'}
										textAlign={'left'}
										onChange={() => {
											setShowIIFLWidget((current) => !current);
										}}
									>
										Also open a free trading account
									</Checkbox>
									{device === 'mobile' && (
										<Button
											variant={'link'}
											color={'blue.300'}
											colorScheme={'blue'}
											size={'sm'}
											w={'fit-content'}
											onClick={handleKnowMoreClick}
										>
											More
										</Button>
									)}
									{device !== 'mobile' && (
										<Popover placement={'right'}>
											<PopoverTrigger>
												<Button
													variant={'link'}
													color={'blue.300'}
													colorScheme={'blue'}
													size={'sm'}
													w={'fit-content'}
												>
													More
												</Button>
											</PopoverTrigger>
											<PopoverContent
												w={'360px'}
												color={'white'}
												background={'#212732'}
												borderWidth={'1px'}
												borderColor={'gray.800'}
											>
												<PopoverBody p={0}>
													<_AccountOpenKnowMore />
												</PopoverBody>
											</PopoverContent>
										</Popover>
									)}
								</HStack>
							)}
						</LightMode>
						{device !== 'mobile' && <SocialIcons />}
					</GridItem>
					<GridItem>
						<HomepageUXSteps />
					</GridItem>
				</Grid>
				{device === 'mobile' && <SocialIcons />}
			</Container>
			{device === 'mobile' && (
				<Drawer
					isOpen={isKnowMoreOpen}
					onClose={onKnowMoreClose}
					placement={'bottom'}
				>
					<DrawerOverlay />
					<DrawerContent color={'white'} background={'#212732'}>
						<DrawerBody p={0}>
							<_AccountOpenKnowMore device={device} onClose={onKnowMoreClose} />
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			)}
		</>
	);
};

export default HomepageHero;
