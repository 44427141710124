import { Circle, Heading, Text, VStack } from '@chakra-ui/react'
import Container from "../../common/Container";
import UxStrategyIcon from "../../icons/ux-strategy";
import UxOrderIcon from "../../icons/ux-order";
import UxTrackIcon from "../../icons/ux-track";

const StepContent = ({title, text, icon}) => {

    return (
        <VStack spacing={10}>
            <Circle bg={'gray.900'} size={'70px'} overflow={'hidden'}>
                {icon}
            </Circle>
            <VStack alignItems="center" spacing={'4'}>
                <Heading color={'white'} fontWeight={'semibold'} fontSize={'1.25rem'}>
                    {title}
                </Heading>
                <Text color={'gray.100'} fontWeight={'medium'} lineHeight={'6'}>
                    {text}
                </Text>
            </VStack>
        </VStack>
    )
}

const _UXStepMobile = () => {
    return (
        <Container>
            <VStack spacing={'16'}>
                <StepContent
                    title={'Pick a basket'}
                    text={'Yobee is for every trader - covering different segments, styles, and risks.'}
                    icon={<UxStrategyIcon height={'60px'}/>}
                />
                <StepContent
                    title={'Place your order'}
                    text={'Trades are executed in your account for the amount set.'}
                    icon={<UxOrderIcon height={'60px'}/>}
                />

                <StepContent
                    title={'Track & exit anytime'}
                    text={'Live order status and real time profits - you’re in control.'}
                    icon={<UxTrackIcon height={'60px'}/>}
                />
            </VStack>
        </Container>

    )
}

export default _UXStepMobile
