import fetch from "unfetch";

export const dispatcher = async (url, body) => {
    const r = await fetch(`${process.env.NEXT_PUBLIC_LEAD_GEN_BASE_API}${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.NEXT_PUBLIC_LEAD_GEN_API_KEY
        },
        ...(body && {body: JSON.stringify(body)})
    })

    if (!r.ok) {
        const error = new Error('An error occurred while dispatching action')
        error.info = await r.json()
        error.status = r.status
        throw error
    }
    return r.json()
}

