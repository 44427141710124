import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { assert } from "superstruct";

const useDispatchAction = (url, dispatcher, schema) => {

    const [state, setState] = useState({
        isDispatching: false,
        response: null,
        status: 'idle',
        error: null
    })
    
    const dispatch = useCallback(async (body) => {

        assert(body, schema)

        setState(prev => ({
            ...prev,
            isDispatching: true,
            response: null,
            error: null
        }))

        return dispatcher(url, body).then(r => {
            setState(prev => ({
                ...prev,
                isDispatching: false,
                response: r,
                status: 'success'
            }))
        }).catch(error => {
            setState(prev => ({
                ...prev,
                error: error,
                isDispatching: false,
                status: 'error'
            }))
        })
    }, [url, dispatcher])

    return {
        dispatch,
        status: state.status,
        isDispatching: state.isDispatching,
        response: state.response,
        error: state.error
    }

}

export default useDispatchAction