import {Box, DarkMode} from '@chakra-ui/react';
import HomepageHero from '../components/home/hero';
import {Center} from '@chakra-ui/layout';
import Head from 'next/head';
import Script from 'next/script';
import {setCookie} from 'nookies';

const HomePage = () => {
	return (
		<>
			<Head>
				<base href="/" />
			</Head>

			<Script
				type="text/javascript"
				src="https://www.public.yobee.co.in/broker/iifl/login-widget-v1.6.js"
				strategy={'lazyOnload'}
			/>

			<DarkMode>
				<Center w={'100vw'} minH={'100vh'} bg={'otherBlack.bg'}>
					<HomepageHero />
				</Center>
			</DarkMode>
		</>
	);
};

export async function getServerSideProps(ctx) {
	const params = Object.fromEntries(new URLSearchParams(ctx.query));
	setCookie(ctx, 'urlParams', JSON.stringify(params));
	return {props: {}};
}

export default HomePage;
