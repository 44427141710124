import useDispatchAction from '../../hooks/dispatchAction';
import {dispatcher} from '../dispatcher';
import {
	boolean,
	defaulted,
	number,
	object,
	optional,
	string,
	array,
	record,
} from 'superstruct';

const useLeadGenCreate = () => {
	const schema = object({
		broker_id: string(),
		mobile: number(),
		email: optional(string()),
		otp_verified: defaulted(boolean(), false),
		url_parameters: optional(array(record(string(), string()))),
	});

	return useDispatchAction('/create', dispatcher, schema);
};

export default useLeadGenCreate;
