import {Button, Image, Stack, useBoolean} from '@chakra-ui/react';
import {FaCheck} from 'react-icons/fa';
import useLeadGenCreate from '../../apiHandlers/leadGen/create';
import {Input, InputGroup, InputLeftElement} from '@chakra-ui/input';
import {Text} from '@chakra-ui/layout';
import {useState} from 'react';
import {parseCookies} from 'nookies';

const mobileRegex = /^\d{10}$/;

const EarlyAccessSignup = () => {
	const [mobile, setMobile] = useState('');
	const [error, setError] = useBoolean(false);
	const urlParams = parseCookies(null)['urlParams']
		? JSON.parse(parseCookies(null)['urlParams'])
		: {};
	const {dispatch, isDispatching, status} = useLeadGenCreate();

	const handleChange = async (e) => {
		if (e.key === 'Enter') {
			await handleSubmit();
		} else {
			setMobile(e.target.value);
		}
	};

	const handleSubmit = async () => {
		if (status !== 'success') {
			setError.off();
			if (mobileRegex.test(mobile)) {
				await dispatch({
					mobile: parseInt(mobile, 10),
					broker_id: 'yobee',
					otp_verified: false,
					...(Object.keys(urlParams).length > 0
						? {url_parameters: [urlParams]}
						: {url_parameters: []}),
				});
			} else {
				setError.on();
			}
		}
	};

	const SubmitButton = () => (
		<Button
			size={'lg'}
			minH={'40px'}
			fontWeight={'bold'}
			lineHeight={'normal'}
			h={['54px', null, '54px']}
			colorScheme={status === 'success' ? 'green' : 'blue'}
			bg={'blue.500'}
			color={'white'}
			isLoading={isDispatching}
			onClick={handleSubmit}
		>
			{status === 'success' ? <FaCheck /> : 'Get Started'}
		</Button>
	);

	return (
		<Stack spacing={4}>
			<InputGroup size={'lg'} h={['53px', null, '53px']}>
				<InputLeftElement
					w={10}
					pointerEvents="none"
					children={
						<Image src={'/icons/mobile.svg'} h={'20px'} w={'20px'} mt={'2px'} />
					}
				/>
				<Input
					h={'100%'}
					pl={10}
					placeholder={'Enter Mobile No.'}
					bg={'otherWhite.pure'}
					value={mobile}
					onChange={handleChange}
					onKeyDown={handleChange}
					isInvalid={error}
					isDisabled={isDispatching}
					errorBorderColor={'red.500'}
					_placeholder={{fontSize: '16px', mt: 2}}
				/>
			</InputGroup>
			<SubmitButton />
			{error && (
				<Text color={'red.500'} fontSize={'sm'}>
					Please enter a valid mobile
				</Text>
			)}
		</Stack>
	);
};

export default EarlyAccessSignup;
