import BreakpointResponsive from "../../common/BreakpointResponsive";
import _UXStepMobile from "./mobile";
import _UXStepNonMobile from "./nonMobile";

const HomepageUXSteps = () => {
    return (
        <BreakpointResponsive
            mobile={<_UXStepMobile/>}
            tablet={<_UXStepNonMobile/>}
            desktop={<_UXStepNonMobile/>}
        />
    )
}

export default HomepageUXSteps