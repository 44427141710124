import { Icon } from '@chakra-ui/react'
import React from 'react'
import colors from '../../theme/colors'

function UxOrderIcon({ active = false, ...props }) {
    return (
        <Icon
            viewBox={'0 0 80 80'}
            width={'20'}
            height={'20'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle r={40} transform="matrix(1 0 0 -1 40.536 40)" fill={active ? colors.blue.accent : colors.black} />
            <path
                d="M21.286 19a1.75 1.75 0 100 3.5h.86a1.75 1.75 0 011.683 1.27l5.55 19.422A5.25 5.25 0 0034.425 47h15.99a5.25 5.25 0 004.875-3.3l5.16-12.9c.92-2.3-.773-4.8-3.25-4.8H28.106l-.912-3.192A5.25 5.25 0 0022.146 19h-.86zm14 42a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5zm14 0a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5z"
                fill={active ? colors.white : colors.blue.accent}
            />
        </Icon>
    )
}

export default UxOrderIcon
