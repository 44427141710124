import {useBreakpoint} from "@chakra-ui/media-query";

const BreakpointResponsive = ({mobile = null, tablet = null, desktop = null}) => {
    const breakPoint = useBreakpoint()
    return (
        <>
            {(breakPoint === 'base' || breakPoint === 'sm') && mobile}
            {breakPoint === 'md' && tablet}
            {(breakPoint === 'lg' || breakPoint === 'xl' || breakPoint === '2xl') && desktop}
        </>
    )
}

export default BreakpointResponsive