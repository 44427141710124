import { Icon } from '@chakra-ui/react'
import colors from '../../../theme/colors'
import { motion } from 'framer-motion'
import { useIntersectionObserver } from '../../common/IntersectionObserver'

const _UXStepProgressBar = ({ duration = 5, active = false, onComplete }) => {
    const size = '40px'
    const radius = 32
    const circumference = Math.ceil(2 * Math.PI * radius)
    const { inView } = useIntersectionObserver()

    const transition = {
        duration: duration,
        ease: 'easeIn',
    }
    const variants = {
        start: {
            strokeDashoffset: 0,
            transition,
        },
        finish: {
            strokeDashoffset: circumference,
            transition,
        },
    }
    return (
        <Icon
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            stroke={'green.accent'}
            __css={{
                transform: 'rotate(120deg) scaleX(-1)',
            }}
            cursor={'pointer'}
        >
            {active && inView && (
                <motion.circle
                    cx={'50'}
                    cy={'50'}
                    r={radius}
                    strokeWidth={4}
                    fill={colors.otherBlack.bg}
                    strokeDasharray={circumference}
                    strokeDashoffset={0}
                    variants={variants}
                    initial={'start'}
                    animate={'finish'}
                    onAnimationComplete={onComplete}
                />
            )}
            <motion.circle
                cx={'50'}
                cy={'50'}
                r={10}
                stroke={'none'}
                fill={active ? colors.white : colors.gray['800']}
                variants={{ active: { r: 10 }, inactive: { r: 18 } }}
                initial={'inactive'}
                animate={active ? 'active' : 'inactive'}
            />
        </Icon>
    )
}

export default _UXStepProgressBar
